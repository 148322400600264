<app-side-top-nav-bar [title]="heading" [dynamicPath]="'home'" [backPath]="'home'"> </app-side-top-nav-bar>
<div class="soda-recovery-section">
    <div class="row">
        <img src = "../../../assets/green-boiler.png"  class="green-boiler"/>
        <img src = "../../../assets/arr-1.png" class="arrow-1" />
        <a (click)="routeToModule('evaporator')"><img src = "../../../assets/evaporator.png" class="evaporator"/> </a>
    </div>
    <div class="row">
        <img src = "../../../assets/arr-3.png" />
        <img src = "../../../assets/filtration.png" class="filtration"/>
        <img src = "../../../assets/arr-2.png" />
    </div>
</div>