import { Component,ElementRef, HostListener, OnInit } from '@angular/core';
// import { ButtonService } from './services/button-service/button.service';

import { NavigationStart, Router } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'kuantum_frontend';
  @HostListener('document:keydown', ['$event'])
  disableSpecialKeys(event: KeyboardEvent): void {
    // Add keys you want to disable
    if (event.ctrlKey || event.altKey || event.key === 'F12' || event.key === 'F1' || event.key === 'F2') {
      event.preventDefault();
    }
  }
  disableRightClick(event: MouseEvent): void {
    event.preventDefault();
  }
}
