<link href="https://fonts.googleapis.com/css?family=Numans" rel="stylesheet" />
<div class="top_navbar">
  <div>
    <!-- <img src="../../../../assets/kp-logo.png" class="KP_logo" /> -->
    <img src="../../../assets/Bert Labs.png" class="KP_logo" />
    <h2 class="Heading">{{ title }}</h2>
    <a (click)="logout()"
      ><img src="../../../../assets/user-logout.png" class="profile" />
    </a>
  </div>
</div>

<div class="menu">
  <div class="sidebar-inner">
    <ul class="no-bullet">
      <li>
        <div (click)="navigateToHome()">
          <img src="../../../../assets/home-icon.png" class="logo" />
        </div>
      </li>
      <li>
        <div (click)="navigateBack()">
          <img src="../../../../assets/back-icon.png" class="logo" />
        </div>
      </li>
      <li style="margin-top: 825%">
        <div class="logo">
          <img src="../../../../assets/settings-icon.png" class="logo" />
        </div>
      </li>
      <li>
        <div class="logo">
          <img src="../../../../assets/info-icon.png" class="logo" />
        </div>
      </li>
    </ul>
  </div>

  <div class="hamburger">
    <i class="fa-bars"></i>
  </div>
</div>
