import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-inputs',
  templateUrl: './inputs.component.html',
  styleUrls: ['./inputs.component.css']
})
export class InputsComponent {
  wblFlowRate: any;
  wblDensity: any;
  steamFlow: any;
  steamPressure: any;
  vaccumPressure: any;
  updatedSteamFlow: any;

  @Output() predict: EventEmitter<any> = new EventEmitter();
  @Output() optimise: EventEmitter<any> = new EventEmitter();

  sendData() {

    let reqData = {
      "WBL Flow Rate": +this.wblFlowRate,
      "WBL Density" : +this.wblDensity,
      "Steam Flow" : +this.steamFlow,
      "Steam Pressure" : +this.steamPressure,
      "Vacuum Pressure" : +this.vaccumPressure,
      "Delta P 7" : 0,
      "Delta P 6" : 0,
      "Delta P 5" : 0,
      "Delta P 4" : 0,
      "Delta P 3" : 0,
      "Delta P 2" : 0,
      "Delta P 1b" : 0
    }
    this.predict.emit(reqData);
  }

  sendOptimiseData() {
    if(this.steamFlow >= 30 && this.steamFlow<=33)
    {
      this.updatedSteamFlow = this.steamFlow - 3;
    }
    else if(this.steamFlow >= 27 && this.steamFlow<30)
    {
      this.updatedSteamFlow = this.steamFlow - 2.75;
    }
    else if(this.steamFlow >= 20 && this.steamFlow<27)
    {
      this.updatedSteamFlow = this.steamFlow - 2.65;
    }
    else if(this.steamFlow<20)
    {
      this.updatedSteamFlow = this.steamFlow - 2.7;
    }
    else
    {
      this.updatedSteamFlow = this.steamFlow - 3.2;
    }

    let reqData = {
      "WBL Flow Rate": +this.wblFlowRate,
      "WBL Density" : +this.wblDensity,
      "Steam Flow" : +this.updatedSteamFlow,
      "Steam Pressure" : 1.2,
      "Vacuum Pressure" : -690,
      "Delta P 7" : 0,
      "Delta P 6" : 0,
      "Delta P 5" : 0,
      "Delta P 4" : 0,
      "Delta P 3" : 0,
      "Delta P 2" : 0,
      "Delta P 1b" : 0
      
    }
    this.optimise.emit(reqData);
  }
}
