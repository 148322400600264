import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-green-flow',
  templateUrl: './green-flow.component.html',
  styleUrls: ['./green-flow.component.css']
})
export class GreenFlowComponent {
  @Input() flow: any;
}
