<div
  style="
    position: absolute;
    left: 18%;
    top: 29%;
  "
>
  <img src="../../assets/kpi/kpi2.svg" />
  <div style="position: absolute; left: 48%; top: 40.5%">{{ steam_BL }}</div>
  <div style="position: absolute; left: 48.5%; top: 52.5%">{{ steam_economy }}</div>

  <div style="position: absolute; left: 76%; top: 40.5%">{{ steam_wl }}</div>
  <div style="position: absolute; left: 77.5%; top: 52.5%">{{ steam_economy_o }}</div>
  <div
  style="
 
    position: absolute;
    top: 82%; 
    left: 52%;
    /* background-color: aquamarine; */
    transform: translate(-50%, -50%); 
    z-index: 10; 
  "
  >
  {{ steam_red_percent | number: '1.2-2' }}
  </div>
</div>
