import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-kpi-opt',
  templateUrl: './kpi-opt.component.html',
  styleUrls: ['./kpi-opt.component.css']
})
export class KpiOptComponent {
  @Input() steam_flow: any;
  @Input() steam_wl: any;
  @Input() steam_economy: any;
  @Input() steam_red: any;
  @Input() steam_red_percent: any;
  @Input() fuel_red: any;
}
