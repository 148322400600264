import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService } from '../authentication.service';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'bl-auth-form',
  templateUrl: './auth-form.component.html',
  styleUrls: ['./auth-form.component.css']
})
export class AuthFormComponent implements OnInit {
  @ViewChild('form') form!: NgForm;

  public forgotMode: boolean = false;
  public forgotModeSent: boolean = false;

  public $error = new BehaviorSubject<{
    message: string;
    status: number;
  } | null>(null);

  constructor(
    public authService: AuthenticationService,
    private router: Router
  ) {}

  toggleForgotMode() {
    this.forgotMode = !this.forgotMode;
    this.forgotModeSent = false;
  }

  handleSubmit() {
    if (this.forgotMode) {
      this.handlePasswordReset();
    } else {
      this.handleLogin();
    }
  }

  handlePasswordReset() {
    this.forgotModeSent = true;
    const { email } = this.form?.value;
    console.log(email);
    // server call to send reset link
  }

  handleLogin() {
    const { email, password } = this.form?.value;
    this.authService.logIn(email, password).subscribe({
      error: err => {
        this.$error.next(err);
        // resolve after 2500 ms
        setTimeout(() => {
          this.$error.next(null);
        }, 2500);
      }
    });
  }

  ngOnInit(): void {
    this.authService.isAuthenticated().subscribe(isAuth => {
      // goto app if already logged in
      if (isAuth) this.router.navigate(['home']);
    });
  }
}
