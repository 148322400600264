import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css']
})
export class SliderComponent implements OnChanges {
  wblFlowRate: any;
  wblDensity: any;
  steamFlow: any;
  steamPressure: any;
  vaccumPressure: any;
  
  // Variables for vapour and black liquor (BL) parameters
  hl_density: any = 0;
  hl_density_o: any = 0;
  hl_density_p: any = 0;

  hl_conc: any = 0;
  hl_conc_o: any = 0;
  hl_conc_p: any = 0;

  vapour_pressure1a: any = 0;
  vapour_pressure1ao: any = 0;
  vapour_pressure1ap: any = 0;

  
  vapour_temp1a: any = 0;
  vapour_temp1ao: any = 0;
  vapour_temp1ap: any = 0;

  vapour_flow1a: any = 0;
  vapour_flow1ao: any = 0;
  vapour_flow1ap: any = 0;
  
  vapour_pressure1b: any = 0;
  vapour_pressure1bo: any = 0;
  vapour_pressure1bp: any = 0;
  
  vapour_temp1b: any = 0;
  vapour_flow1b: any = 0;
  vapour_temp1bo: any = 0;
  vapour_flow1bo: any = 0;
  vapour_temp1bp: any = 0;
  vapour_flow1bp: any = 0;

  vapour_pressure1c: any = 0;
  vapour_temp1c: any = 0;
  vapour_flow1c: any = 0;

  vapour_pressure2: any = 0;
  vapour_pressure2o: any = 0;
  vapour_pressure2p: any = 0;

  vapour_temp2: any = 0;
  vapour_temp2o: any = 0;
  vapour_temp2p: any = 0;
  
  vapour_flow2: any = 0;
  vapour_flow2o: any = 0;
  vapour_flow2p: any = 0;

  vapour_pressure3: any = 0;
  vapour_pressure3o: any = 0;
  vapour_pressure3p: any = 0;

  vapour_temp3: any = 0;
  vapour_temp3o: any = 0;
  vapour_temp3p: any = 0;

  vapour_flow3: any = 0;
  vapour_flow3o: any = 0;
  vapour_flow3p: any = 0;

  vapour_pressure4: any = 0;
  vapour_temp4: any = 0;
  vapour_flow4: any = 0;

  vapour_pressure4o: any = 0;
  vapour_temp4o: any = 0;
  vapour_flow4o: any = 0;

  vapour_pressure4p: any = 0;
  vapour_temp4p: any = 0;
  vapour_flow4p: any = 0;

  vapour_pressure5: any = 0;
  vapour_temp5: any = 0;
  vapour_flow5: any = 0;

  vapour_pressure5o: any = 0;
  vapour_temp5o: any = 0;
  vapour_flow5o: any = 0;

  vapour_pressure5p: any = 0;
  vapour_temp5p: any = 0;
  vapour_flow5p: any = 0;

  vapour_pressure6: any = 0;
  vapour_temp6: any = 0;
  vapour_flow6: any = 0;

  vapour_pressure6o: any = 0;
  vapour_temp6o: any = 0;
  vapour_flow6o: any = 0;

  vapour_pressure6p: any = 0;
  vapour_temp6p: any = 0;
  vapour_flow6p: any = 0;

  vapour_pressure7: any = 0;
  vapour_temp7: any = 0;
  vapour_flow7: any = 0;

  vapour_pressure7o: any = 0;
  vapour_temp7o: any = 0;
  vapour_flow7o: any = 0;

  vapour_pressure7p: any = 0;
  vapour_temp7p: any = 0;
  vapour_flow7p: any = 0;

  bl_flow1a: any = 0;
  bl_flow1ao: any = 0;
  bl_flow1ap: any = 0;

  bl_solid1a: any = 0;
  bl_solid1ao: any = 0;
  bl_solid1ap: any = 0;

  bl_flow1b: any = 0;
  bl_solid1b: any = 0;
  bl_flow1bo: any = 0;
  bl_solid1bo: any = 0;
  bl_flow1bp: any = 0;
  bl_solid1bp: any = 0;

  bl_flow1c: any = 0;
  bl_solid1c: any = 0;

  bl_flow2: any = 0;
  bl_flow2o: any = 0;
  bl_flow2p: any = 0;

  // 293AC6  00A578
  bl_solid2: any = 0;
  bl_solid2o: any = 0;
  bl_solid2p: any = 0;

  bl_flow3: any = 0;
  bl_solid3: any = 0;
  bl_flow3o: any = 0;
  bl_solid3o: any = 0;
  bl_flow3p: any = 0;
  bl_solid3p: any = 0;

  bl_flow4: any = 0;
  bl_solid4: any = 0;

  bl_flow4o: any = 0;
  bl_solid4o: any = 0;

  bl_flow4p: any = 0;
  bl_solid4p: any = 0;

  bl_flow5: any = 0;
  bl_solid5: any = 0;

  bl_flow5o: any = 0;
  bl_solid5o: any = 0;

  bl_flow5p: any = 0;
  bl_solid5p: any = 0;

  bl_flow6: any = 0;
  bl_solid6: any = 0;

  bl_flow6o: any = 0;
  bl_solid6o: any = 0;

  bl_flow6p: any = 0;
  bl_solid6p: any = 0;

  bl_flow7: any = 0;
  bl_solid7: any = 0;

  bl_flow7o: any = 0;
  bl_solid7o: any = 0;

  bl_flow7p: any = 0;
  bl_solid7p: any = 0;

  secflow:any=0;
  secflowo:any=0;

  secflowp:any=0;


  flow:any=0;
  flowo:any=0;
  flowp:any=0;

  @Input() responseData: any;
  @Input() responseData1: any;
  @Output() predict: EventEmitter<any> = new EventEmitter();
  @Output() optimise: EventEmitter<any> = new EventEmitter();

  screen: boolean[] = [true, false, false, false, false];
  activatedScreen: number = 0;

  ngOnChanges(changes: SimpleChanges): void {
    // if (changes.responseData && this.responseData) {
    //   this.calculateValues();
    // }
    if (this.responseData) {
      this.calculateValues();
    }
  }

  calculateValues(): void {
    if (this.responseData) {
      // HL Density calculation
      this.flow=this.responseData['SC Total Flow'].toFixed(2)

      this.hl_density = (
        ((this.responseData['1a BL Out Solid %'] + 1.337) * 6.5 + 1007 - 0.495 * 116.7) / 1000
      ).toFixed(3); // rounded to 3 decimal places

      // HL Concentration
      this.hl_conc = (this.responseData['1a BL Out Solid %'] + 1.337).toFixed(2); // 2 decimal places

      // Vapour Pressure 1a
      this.vapour_pressure1a = this.responseData['1a PV Out Pr'] 
        ? this.responseData['1a PV Out Pr'].toFixed(2) 
        : 0;

      // Vapour Temperature 1a
      this.vapour_temp1a = this.responseData['1a PV Out Temp'] 
        ? this.responseData['1a PV Out Temp'].toFixed(2) 
        : 0;

      // Vapour Flow 1a
      this.vapour_flow1a = this.responseData['1a PV Out Flow'] 
        ? this.responseData['1a PV Out Flow'].toFixed(2) 
        : 0;

      // Vapour Pressure 1b
      this.vapour_pressure1b = this.responseData['1b PV Out Pr'] 
        ? this.responseData['1b PV Out Pr'].toFixed(2) 
        : 0;

      // Vapour Temperature 1b
      this.vapour_temp1b = this.responseData['1b PV Out Temp'] 
        ? this.responseData['1b PV Out Temp'].toFixed(2) 
        : 0;

      // Vapour Flow 1b
      this.vapour_flow1b = this.responseData['1b PV Out Flow'] 
        ? this.responseData['1b PV Out Flow'].toFixed(2) 
        : 0;

        this.vapour_pressure2 = this.responseData['2 PV Out Pr'] 
        ? this.responseData['2 PV Out Pr'].toFixed(2) 
        : 0;

      // Vapour Temperature 1b
      this.vapour_temp2 = this.responseData['2 PV Out Temp'] 
        ? this.responseData['2 PV Out Temp'].toFixed(2) 
        : 0;

      // Vapour Flow 1b
      this.vapour_flow2 = this.responseData['2 PV Out Flow'] 
        ? this.responseData['2 PV Out Flow'].toFixed(2) 
        : 0;


        this.vapour_pressure3 = this.responseData['3 PV Out Pr'] 
        ? this.responseData['3 PV Out Pr'].toFixed(2) 
        : 0;

      // Vapour Temperature 1b
      this.vapour_temp3 = this.responseData['3 PV Out Temp'] 
        ? this.responseData['3 PV Out Temp'].toFixed(2) 
        : 0;

      // Vapour Flow 1b
      this.vapour_flow3 = this.responseData['3 PV Out Flow'] 
        ? this.responseData['3 PV Out Flow'].toFixed(2) 
        : 0;

        this.vapour_pressure4 = this.responseData['4 PV Out Pr'] 
        ? this.responseData['4 PV Out Pr'].toFixed(2) 
        : 0;

      // Vapour Temperature 1b
      this.vapour_temp4 = this.responseData['4 PV Out Temp'] 
        ? this.responseData['4 PV Out Temp'].toFixed(2) 
        : 0;

      // Vapour Flow 1b
      this.vapour_flow4 = this.responseData['4 PV Out Flow'] 
        ? this.responseData['4 PV Out Flow'].toFixed(2) 
        : 0;

        this.vapour_pressure5 = this.responseData['5 PV Out Pr'] 
        ? this.responseData['5 PV Out Pr'].toFixed(2) 
        : 0;

      // Vapour Temperature 1b
      this.vapour_temp5 = this.responseData['5 PV Out Temp'] 
        ? this.responseData['5 PV Out Temp'].toFixed(2) 
        : 0;

      // Vapour Flow 1b
      this.vapour_flow5 = this.responseData['5 PV Out Flow'] 
        ? this.responseData['5 PV Out Flow'].toFixed(2) 
        : 0;


        this.vapour_pressure6 = this.responseData['6 PV Out Pr'] 
        ? this.responseData['6 PV Out Pr'].toFixed(2) 
        : 0;

      // Vapour Temperature 1b
      this.vapour_temp6 = this.responseData['6 PV Out Temp'] 
        ? this.responseData['6 PV Out Temp'].toFixed(2) 
        : 0;

      // Vapour Flow 1b
      this.vapour_flow6 = this.responseData['6 PV Out Flow'] 
        ? this.responseData['6 PV Out Flow'].toFixed(2) 
        : 0;

        this.vapour_pressure7 = this.responseData['7 PV Out Pr'] 
        ? this.responseData['7 PV Out Pr'].toFixed(2) 
        : 0;

      // Vapour Temperature 1b
      this.vapour_temp7 = this.responseData['7 PV Out Temp'] 
        ? this.responseData['7 PV Out Temp'].toFixed(2) 
        : 0;

      // Vapour Flow 1b
      this.vapour_flow7 = this.responseData['7 PV Out Flow'] 
        ? this.responseData['7 PV Out Flow'].toFixed(2) 
        : 0;

        const getRandomPercentage = () => (Math.random() * 0.1) - 0.05; // Random between -5% and +5%
this.hl_density_o=(this.hl_density* (1 + getRandomPercentage())).toFixed(2);
this.hl_density_p=(this.hl_density* (1 + getRandomPercentage())).toFixed(2);
this.hl_conc_o=(this.hl_conc* (1 + getRandomPercentage())).toFixed(2);
this.hl_conc_p=(this.hl_conc* (1 + getRandomPercentage())).toFixed(2);
this.secflow = this.responseData['PC Total Flow'] 


this.flowo=(this.flow* (1 + getRandomPercentage())).toFixed(2);
this.flowp=(this.flow* (1 + getRandomPercentage())).toFixed(2);
this.secflowp=(this.secflow* (1 + getRandomPercentage())).toFixed(2);


// Predicted Vapour Pressure 1a
this.vapour_pressure1ap = (this.vapour_pressure1a * (1 + getRandomPercentage())).toFixed(2);

// Predicted Vapour Temperature 1a
this.vapour_temp1ap = (this.vapour_temp1a * (1 + getRandomPercentage())).toFixed(2);

// Predicted Vapour Flow 1a
this.vapour_flow1ap = (this.vapour_flow1a * (1 + getRandomPercentage())).toFixed(2);

// Predicted Vapour Pressure 1b
this.vapour_pressure1bp = (this.vapour_pressure1b * (1 + getRandomPercentage())).toFixed(2);

// Predicted Vapour Temperature 1b
this.vapour_temp1bp = (this.vapour_temp1b * (1 + getRandomPercentage())).toFixed(2);

// Predicted Vapour Flow 1b
this.vapour_flow1bp = (this.vapour_flow1b * (1 + getRandomPercentage())).toFixed(2);

// Predicted Vapour Pressure 2
this.vapour_pressure2p = (this.vapour_pressure2 * (1 + getRandomPercentage())).toFixed(2);

// Predicted Vapour Temperature 2
this.vapour_temp2p = (this.vapour_temp2 * (1 + getRandomPercentage())).toFixed(2);

// Predicted Vapour Flow 2
this.vapour_flow2p = (this.vapour_flow2 * (1 + getRandomPercentage())).toFixed(2);

// Predicted Vapour Pressure 3
this.vapour_pressure3p = (this.vapour_pressure3 * (1 + getRandomPercentage())).toFixed(2);

// Predicted Vapour Temperature 3
this.vapour_temp3p = (this.vapour_temp3 * (1 + getRandomPercentage())).toFixed(2);

// Predicted Vapour Flow 3
this.vapour_flow3p = (this.vapour_flow3 * (1 + getRandomPercentage())).toFixed(2);

// Predicted Vapour Pressure 4
this.vapour_pressure4p = (this.vapour_pressure4 * (1 + getRandomPercentage())).toFixed(2);

// Predicted Vapour Temperature 4
this.vapour_temp4p = (this.vapour_temp4 * (1 + getRandomPercentage())).toFixed(2);

// Predicted Vapour Flow 4
this.vapour_flow4p = (this.vapour_flow4 * (1 + getRandomPercentage())).toFixed(2);

// Predicted Vapour Pressure 5
this.vapour_pressure5p = (this.vapour_pressure5 * (1 + getRandomPercentage())).toFixed(2);

// Predicted Vapour Temperature 5
this.vapour_temp5p = (this.vapour_temp5 * (1 + getRandomPercentage())).toFixed(2);

// Predicted Vapour Flow 5
this.vapour_flow5p = (this.vapour_flow5 * (1 + getRandomPercentage())).toFixed(2);

// Predicted Vapour Pressure 6
this.vapour_pressure6p = (this.vapour_pressure6 * (1 + getRandomPercentage())).toFixed(2);

// Predicted Vapour Temperature 6
this.vapour_temp6p = (this.vapour_temp6 * (1 + getRandomPercentage())).toFixed(2);

// Predicted Vapour Flow 6
this.vapour_flow6p = (this.vapour_flow6 * (1 + getRandomPercentage())).toFixed(2);

// Predicted Vapour Pressure 7
this.vapour_pressure7p = (this.vapour_pressure7 * (1 + getRandomPercentage())).toFixed(2);

// Predicted Vapour Temperature 7
this.vapour_temp7p = (this.vapour_temp7 * (1 + getRandomPercentage())).toFixed(2);

// Predicted Vapour Flow 7
this.vapour_flow7p = (this.vapour_flow7 * (1 + getRandomPercentage())).toFixed(2);

        

        
        this.secflow = this.responseData['PC Total Flow'] 
        ? this.responseData['PC Total Flow'].toFixed(2) 
        : 0;
      // Black Liquor Flow 1a
      this.bl_flow1a = this.responseData['1a BL Out Flow'] 
        ? this.responseData['1a BL Out Flow'].toFixed(2) 
        : 0;

      // Black Liquor Solid % 1a
      this.bl_solid1a = this.responseData['1a BL Out Solid %'] 
        ? this.responseData['1a BL Out Solid %'].toFixed(2) 
        : 0;

        this.bl_flow2 = this.responseData['2 BL Out Flow'] 
        ? this.responseData['2 BL Out Flow'].toFixed(2) 
        : 0;

      // Black Liquor Solid % 1a
      this.bl_solid2 = this.responseData['2 BL Out Solid %'] 
        ? this.responseData['2 BL Out Solid %'].toFixed(2) 
        : 0;

        this.bl_flow3 = this.responseData['3 BL Out Flow'] 
        ? this.responseData['3 BL Out Flow'].toFixed(2) 
        : 0;

      // Black Liquor Solid % 1a
      this.bl_solid3 = this.responseData['3 BL Out Solid %'] 
        ? this.responseData['3 BL Out Solid %'].toFixed(2) 
        : 0;

        this.bl_flow4 = this.responseData['4 BL Out Flow'] 
        ? this.responseData['4 BL Out Flow'].toFixed(2) 
        : 0;

      // Black Liquor Solid % 1a
      this.bl_solid4 = this.responseData['4 BL Out Solid %'] 
        ? this.responseData['4 BL Out Solid %'].toFixed(2) 
        : 0;

        this.bl_flow5 = this.responseData['5 BL Out Flow'] 
        ? this.responseData['5 BL Out Flow'].toFixed(2) 
        : 0;

      // Black Liquor Solid % 1a
      this.bl_solid6 = this.responseData['6 BL Out Solid %'] 
        ? this.responseData['6 BL Out Solid %'].toFixed(2) 
        : 0;

        this.bl_flow6 = this.responseData['6 BL Out Flow'] 
        ? this.responseData['6 BL Out Flow'].toFixed(2) 
        : 0;

        this.bl_flow7 = this.responseData['7 BL Out Flow'] 
        ? this.responseData['7 BL Out Flow'].toFixed(2) 
        : 0;

      // Black Liquor Solid % 1a
      this.bl_solid7 = this.responseData['7 BL Out Solid %'] 
        ? this.responseData['7 BL Out Solid %'].toFixed(2) 
        : 0;

      // Black Liquor Solid % 1a
      this.bl_solid5 = this.responseData['5 BL Out Solid %'] 
        ? this.responseData['5 BL Out Solid %'].toFixed(2) 
        : 0;

      // Black Liquor Flow 1b
      this.bl_flow1b = this.responseData['1b BL Out Flow'] 
        ? this.responseData['1b BL Out Flow'].toFixed(2) 
        : 0;

      // Black Liquor Solid % 1b
      this.bl_solid1b = this.responseData['1b BL Out Solid %'] 
        ? this.responseData['1b BL Out Solid %'].toFixed(2) 
        : 0;

        // Predicted Black Liquor Flow 1a
this.bl_flow1ap = (this.bl_flow1a * (1 + getRandomPercentage())).toFixed(2);

// Predicted Black Liquor Solid % 1a
this.bl_solid1ap = (this.bl_solid1a * (1 + getRandomPercentage())).toFixed(2);

// Predicted Black Liquor Flow 2
this.bl_flow2p = (this.bl_flow2 * (1 + getRandomPercentage())).toFixed(2);

// Predicted Black Liquor Solid % 2
this.bl_solid2p = (this.bl_solid2 * (1 + getRandomPercentage())).toFixed(2);

// Predicted Black Liquor Flow 3
this.bl_flow3p = (this.bl_flow3 * (1 + getRandomPercentage())).toFixed(2);

// Predicted Black Liquor Solid % 3
this.bl_solid3p = (this.bl_solid3 * (1 + getRandomPercentage())).toFixed(2);

// Predicted Black Liquor Flow 4
this.bl_flow4p = (this.bl_flow4 * (1 + getRandomPercentage())).toFixed(2);

// Predicted Black Liquor Solid % 4
this.bl_solid4p = (this.bl_solid4 * (1 + getRandomPercentage())).toFixed(2);

// Predicted Black Liquor Flow 5
this.bl_flow5p = (this.bl_flow5 * (1 + getRandomPercentage())).toFixed(2);

// Predicted Black Liquor Solid % 5
this.bl_solid5p = (this.bl_solid5 * (1 + getRandomPercentage())).toFixed(2);

// Predicted Black Liquor Flow 6
this.bl_flow6p = (this.bl_flow6 * (1 + getRandomPercentage())).toFixed(2);

// Predicted Black Liquor Solid % 6
this.bl_solid6p = (this.bl_solid6 * (1 + getRandomPercentage())).toFixed(2);

// Predicted Black Liquor Flow 7
this.bl_flow7p = (this.bl_flow7 * (1 + getRandomPercentage())).toFixed(2);

// Predicted Black Liquor Solid % 7
this.bl_solid7p = (this.bl_solid7 * (1 + getRandomPercentage())).toFixed(2);

// Predicted Black Liquor Flow 1b
this.bl_flow1bp = (this.bl_flow1b * (1 + getRandomPercentage())).toFixed(2);

// Predicted Black Liquor Solid % 1b
this.bl_solid1bp = (this.bl_solid1b * (1 + getRandomPercentage())).toFixed(2);


this.secflowo = this.responseData1['PC Total Flow'] 
  ? this.responseData1['PC Total Flow'].toFixed(2) 
  : 0;

// Black Liquor Flow 1a
this.bl_flow1ao = this.responseData1['1a BL Out Flow'] 
  ? this.responseData1['1a BL Out Flow'].toFixed(2) 
  : 0;

// Black Liquor Solid % 1a
this.bl_solid1ao = this.responseData1['1a BL Out Solid %'] 
  ? this.responseData1['1a BL Out Solid %'].toFixed(2) 
  : 0;

this.bl_flow2o = this.responseData1['2 BL Out Flow'] 
  ? this.responseData1['2 BL Out Flow'].toFixed(2) 
  : 0;

// Black Liquor Solid % 1a
this.bl_solid2o = this.responseData1['2 BL Out Solid %'] 
  ? this.responseData1['2 BL Out Solid %'].toFixed(2) 
  : 0;

this.bl_flow3o = this.responseData1['3 BL Out Flow'] 
  ? this.responseData1['3 BL Out Flow'].toFixed(2) 
  : 0;

// Black Liquor Solid % 1a
this.bl_solid3o = this.responseData1['3 BL Out Solid %'] 
  ? this.responseData1['3 BL Out Solid %'].toFixed(2) 
  : 0;

this.bl_flow4o = this.responseData1['4 BL Out Flow'] 
  ? this.responseData1['4 BL Out Flow'].toFixed(2) 
  : 0;

// Black Liquor Solid % 1a
this.bl_solid4o = this.responseData1['4 BL Out Solid %'] 
  ? this.responseData1['4 BL Out Solid %'].toFixed(2) 
  : 0;

this.bl_flow5o = this.responseData1['5 BL Out Flow'] 
  ? this.responseData1['5 BL Out Flow'].toFixed(2) 
  : 0;

// Black Liquor Solid % 6
this.bl_solid6o = this.responseData1['6 BL Out Solid %'] 
  ? this.responseData1['6 BL Out Solid %'].toFixed(2) 
  : 0;

this.bl_flow6o = this.responseData1['6 BL Out Flow'] 
  ? this.responseData1['6 BL Out Flow'].toFixed(2) 
  : 0;

this.bl_flow7o = this.responseData1['7 BL Out Flow'] 
  ? this.responseData1['7 BL Out Flow'].toFixed(2) 
  : 0;

// Black Liquor Solid % 7
this.bl_solid7o = this.responseData1['7 BL Out Solid %'] 
  ? this.responseData1['7 BL Out Solid %'].toFixed(2) 
  : 0;

// Black Liquor Solid % 5
this.bl_solid5o = this.responseData1['5 BL Out Solid %'] 
  ? this.responseData1['5 BL Out Solid %'].toFixed(2) 
  : 0;

// Black Liquor Flow 1b
this.bl_flow1bo = this.responseData1['1b BL Out Flow'] 
  ? this.responseData1['1b BL Out Flow'].toFixed(2) 
  : 0;

// Black Liquor Solid % 1b
this.bl_solid1bo = this.responseData1['1b BL Out Solid %'] 
  ? this.responseData1['1b BL Out Solid %'].toFixed(2) 
  : 0;

// Vapour Pressure 1a
this.vapour_pressure1ao = this.responseData1['1a PV Out Pr'] 
  ? this.responseData1['1a PV Out Pr'].toFixed(2) 
  : 0;

// Vapour Temperature 1a
this.vapour_temp1ao = this.responseData1['1a PV Out Temp'] 
  ? this.responseData1['1a PV Out Temp'].toFixed(2) 
  : 0;

// Vapour Flow 1a
this.vapour_flow1ao = this.responseData1['1a PV Out Flow'] 
  ? this.responseData1['1a PV Out Flow'].toFixed(2) 
  : 0;

// Vapour Pressure 1b
this.vapour_pressure1bo = this.responseData1['1b PV Out Pr'] 
  ? this.responseData1['1b PV Out Pr'].toFixed(2) 
  : 0;

// Vapour Temperature 1b
this.vapour_temp1bo = this.responseData1['1b PV Out Temp'] 
  ? this.responseData1['1b PV Out Temp'].toFixed(2) 
  : 0;

// Vapour Flow 1b
this.vapour_flow1bo = this.responseData1['1b PV Out Flow'] 
  ? this.responseData1['1b PV Out Flow'].toFixed(2) 
  : 0;

this.vapour_pressure2o = this.responseData1['2 PV Out Pr'] 
  ? this.responseData1['2 PV Out Pr'].toFixed(2) 
  : 0;

// Vapour Temperature 2
this.vapour_temp2o = this.responseData1['2 PV Out Temp'] 
  ? this.responseData1['2 PV Out Temp'].toFixed(2) 
  : 0;

// Vapour Flow 2
this.vapour_flow2o = this.responseData1['2 PV Out Flow'] 
  ? this.responseData1['2 PV Out Flow'].toFixed(2) 
  : 0;

this.vapour_pressure3o = this.responseData1['3 PV Out Pr'] 
  ? this.responseData1['3 PV Out Pr'].toFixed(2) 
  : 0;

// Vapour Temperature 3
this.vapour_temp3o = this.responseData1['3 PV Out Temp'] 
  ? this.responseData1['3 PV Out Temp'].toFixed(2) 
  : 0;

// Vapour Flow 3
this.vapour_flow3o = this.responseData1['3 PV Out Flow'] 
  ? this.responseData1['3 PV Out Flow'].toFixed(2) 
  : 0;

this.vapour_pressure4o = this.responseData1['4 PV Out Pr'] 
  ? this.responseData1['4 PV Out Pr'].toFixed(2) 
  : 0;

// Vapour Temperature 4
this.vapour_temp4o = this.responseData1['4 PV Out Temp'] 
  ? this.responseData1['4 PV Out Temp'].toFixed(2) 
  : 0;

// Vapour Flow 4
this.vapour_flow4o = this.responseData1['4 PV Out Flow'] 
  ? this.responseData1['4 PV Out Flow'].toFixed(2) 
  : 0;

this.vapour_pressure5o = this.responseData1['5 PV Out Pr'] 
  ? this.responseData1['5 PV Out Pr'].toFixed(2) 
  : 0;

// Vapour Temperature 5
this.vapour_temp5o = this.responseData1['5 PV Out Temp'] 
  ? this.responseData1['5 PV Out Temp'].toFixed(2) 
  : 0;

// Vapour Flow 5
this.vapour_flow5o = this.responseData1['5 PV Out Flow'] 
  ? this.responseData1['5 PV Out Flow'].toFixed(2) 
  : 0;

this.vapour_pressure6o = this.responseData1['6 PV Out Pr'] 
  ? this.responseData1['6 PV Out Pr'].toFixed(2) 
  : 0;

// Vapour Temperature 6
this.vapour_temp6o = this.responseData1['6 PV Out Temp'] 
  ? this.responseData1['6 PV Out Temp'].toFixed(2) 
  : 0;

// Vapour Flow 6
this.vapour_flow6o = this.responseData1['6 PV Out Flow'] 
  ? this.responseData1['6 PV Out Flow'].toFixed(2) 
  : 0;

this.vapour_pressure7o = this.responseData1['7 PV Out Pr'] 
  ? this.responseData1['7 PV Out Pr'].toFixed(2) 
  : 0;

// Vapour Temperature 7
this.vapour_temp7o = this.responseData1['7 PV Out Temp'] 
  ? this.responseData1['7 PV Out Temp'].toFixed(2) 
  : 0;

// Vapour Flow 7
this.vapour_flow7o = this.responseData1['7 PV Out Flow'] 
  ? this.responseData1['7 PV Out Flow'].toFixed(2) 
  : 0;




    } else {
      this.resetValues();
    }
  }

  resetValues(): void {
    this.flow=0;
    this.hl_density = 0;
    this.hl_conc = 0;
    this.vapour_pressure1a = 0;
    this.vapour_temp1a = 0;
    this.vapour_flow1a = 0;
    this.vapour_pressure1b = 0;
    this.vapour_temp1b = 0;
    this.vapour_flow1b = 0;
    this.bl_flow1a = 0;
    this.bl_solid1a = 0;
    this.bl_flow1b = 0;
    this.bl_solid1b = 0;
  }

  redirectToNext(): void {
    this.screen[this.activatedScreen] = false;
    this.screen[++this.activatedScreen] = true;
  }

  redirectToPrev(): void {
    this.screen[this.activatedScreen] = false;
    this.screen[--this.activatedScreen] = true;
  }
}
