<div
  style="
    position: absolute;
    left: 38%;
    top: -1%;
  "
>
  <img src="../../assets/kpi-opt/kpi-opt.png" />
  <div style="position: absolute; left: 18.3%; top: 37%">{{steam_flow}}</div>
  <div style="position: absolute; left: 53%; top: 37%">{{steam_wl}}</div>
  <div style="position: absolute; left: 84%; top: 37%">{{steam_red}}</div>
  <div style="position: absolute; left: 53%; top: 53.4%">{{steam_economy}}</div>
  <div style="position: absolute; left: 84%; top: 53.4%">{{steam_red_percent}}</div>
  <div style="position: absolute; left: 84%; top: 70.4%">{{fuel_red}}</div>
</div>
