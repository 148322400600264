import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'home-component',
  templateUrl: './home-screen.template.html',
  styleUrls: ['./home-screen.component.css']
})

export class HomeScreenComponent {
  @Input() title: any;
  @Input() dynamicPath: any;
  heading: String = 'HOME';
  constructor(
    private router: Router
  ) {}

  logout() {
    localStorage.removeItem('user');
    location.reload();
  }

  routeToModule(_path: any) {
    if(_path === 'soda-recovery') {
      this.router.navigate(['/soda-recovery']);
    }
    else if(_path === 'bleaching') {
      this.router.navigate(['/bleaching']);
    }
    else if(_path === 'feeder') {
      this.router.navigate(['/feeder']);
    }
    else if(_path === 'paper-machine') {
      this.router.navigate(['/paper-machine']);
    }
  }
  
}
