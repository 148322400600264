<app-side-top-nav-bar
  [title]="evaporator"
  [dynamicPath]="'home'"
  [backPath]="'soda-recovery'"
>
</app-side-top-nav-bar>
<div style="height: 940px">
  <div style="position: relative;  top: 3%; height: 100px">
    <app-top-kpi
      [steam_BL]=129
      [steam_economy]="5.3 | number : '1.0-2'"

      [steam_wl]="steam_wl"
      [steam_economy_o]="steam_economy | number : '1.0-2'"
      [steam_red_percent]="steam_red_percent|number:'1.0-2'"


    ></app-top-kpi>
    <!-- <app-kpi-opt [steam_flow]="steam_flow" [steam_wl]="steam_wl" [steam_economy]="steam_economy" [steam_red]="steam_red" [steam_red_percent]="steam_red_percent" [fuel_red]="fuel_red"></app-kpi-opt> -->
  </div>

  
  <div style="position: relative; top: 20%; height: 500px">
    <app-slider [responseData]="responseData"  [responseData1]="responseData1" ></app-slider>
  </div>
  <!-- <div style="position: relative; top: 24%; left: 16%">
    <app-inputs
      (predict)="getPrediction($event)"
      (optimise)="getOptimisation($event)"
    ></app-inputs>
  </div> -->
</div>

