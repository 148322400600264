import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-heavy-liquor',
  templateUrl: './heavy-liquor.component.html',
  styleUrls: ['./heavy-liquor.component.css']
})
export class HeavyLiquorComponent {
  @Input() hl_density: any;
  @Input() hl_conc: any;
}
