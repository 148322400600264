
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'soda-recovery-component',
  templateUrl: './soda-recovery.template.html',
  styleUrls: ['../side-top-nav-bar/side-top-nav-bar.component.css']
})

export class SodaRecoveryComponent {
  @Input() title: any;
  Home: String = 'home';
  heading: String = 'SODA RECOVERY';
  showOption: boolean = true;
  constructor(private router: Router) {}
  logout() {
    localStorage.removeItem('user');
    location.reload();
  }

  routeToModule(path: any) {
    if(path === 'evaporator') {
      this.router.navigate(['/evaporator']);
    }
  }

  navigateBack(path: any) {
    if(path !== undefined) {
      this.router.navigate(['/home']);
    }  
  }

  navigateToHome(path: any) {
    if(path !== undefined) {
      this.router.navigate(['/home']);
    }
  }

}

