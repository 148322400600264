import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-orange-flow',
  templateUrl: './orange-flow.component.html',
  styleUrls: ['./orange-flow.component.css']
})
export class OrangeFlowComponent {
  @Input() flow: any;
}
