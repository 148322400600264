<link href="https://fonts.googleapis.com/css?family=Numans" rel="stylesheet" />
<div style="position: absolute">
  <img src="../../assets/inputs/inputs.png" />
  <form>
    <div class="input-value-form">
      <div class="btn">
        <input
          type="text"
          class="form-control no-outline"
          name="bld"
          [(ngModel)]="wblDensity"
        />
        <input
          type="text"
          class="form-control no-outline"
          name="blf"
          [(ngModel)]="wblFlowRate"
        />
      </div>
      <div class="btn" style="left: 40.2%; gap: 138px">
        <input
          type="text"
          class="form-control no-outline"
          name="pressure"
          [(ngModel)]="steamPressure"
        />
        <input
          type="text"
          class="form-control no-outline"
          name="flowrate"
          [(ngModel)]="steamFlow"
        />
      </div>
      <div class="btn" style="left: 78.3%">
        <input
          type="text"
          class="form-control no-outline"
          name="vaccum"
          [(ngModel)]="vaccumPressure"
        />
      </div>
      <div>
        <button
          class="button-submit"
          style="left: 90.3%; top: 21%"
          type="predict"
        >
          <div
            style="color: #ffffff; font-family: Numans; font-size: 19px"
            (click)="sendData()"
          >
            Predict
          </div>
        </button>
        <button
          class="button-submit"
          style="left: 90.3%; top: 50%"
          type="optimise"
        >
          <div
            style="color: #ffffff; font-family: Numans; font-size: 19px"
            (click)="sendOptimiseData()"
          >
            Optimise
          </div>
        </button>
      </div>
    </div>
  </form>
</div>
