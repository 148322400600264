<div style="height: 500px; position: relative;">
  <div
    *ngIf="screen[0]"
    style="position: absolute; top: 3%; left: 6%; height: 500px; width: 1000px"
  >
    <img src="../../assets/slider/1 2.svg" class="evaporator_2_3" />
    <!-- <div class="input-groupR teampc component" style="display:flex; justify-content:center; align-items:center; top: 14.9%; left: 44%; width: 49px; height: 3%;">
      <input style="color: #D90606; font-weight:600" [ngModel]="RAT_in | number: '1.2-2'" (ngModelChange)="RAT_in = $event" />
    </div> -->
    <div
    style="
      color: #D90606;
      font-weight: 600;
      position: absolute;
      top: 83%; 
      left: 38%; 
      transform: translate(-50%, -50%); 
      z-index: 10;
    "
  >
  {{ hl_density | number: '1.2-2' }}
  </div>   

  <div
    style="
      color: #293AC6;
      font-weight: 600;
      position: absolute;
      top: 83%; 
      left: 42%; 
      transform: translate(-50%, -50%); 
      z-index: 10;
    "
  >
  {{ hl_density_p | number: '1.2-2' }}
  </div> 

  <div
    style="
      color: #00A578;
      font-weight: 600;
      position: absolute;
      top: 83%; 
      left: 46%; 
      transform: translate(-50%, -50%); 
      z-index: 10;
    "
  >
  {{ hl_density_o | number: '1.2-2' }}
  </div> 

  <div
  style="
    color: #D90606;
    font-weight: 600;
    position: absolute;
    top: 88%;  
    left: 38%;
    transform: translate(-50%, -50%); 
    z-index: 10;
  "
>
{{ hl_conc | number: '1.2-2' }}
</div> 

<div
  style="
    color: #293AC6;
    font-weight: 600;
    position: absolute;
    top: 88%;  
    left: 42%;
    transform: translate(-50%, -50%); 
    z-index: 10;
  "
>
{{ hl_conc_p | number: '1.2-2' }}
</div> 

<div
  style="
    color: #00A578;
    font-weight: 600;
    position: absolute;
    top: 88%;  
    left: 46%;
    transform: translate(-50%, -50%); 
    z-index: 10;
  "
>
{{ hl_conc_o | number: '1.2-2' }}
</div> 

<div
style="
  color: #D90606;
  font-weight: 600;
  position: absolute;
  top: 34.7%; 
  left: 104%;
  transform: translate(-50%, -50%); 
  z-index: 10; 
"
>
{{ vapour_pressure1a | number: '1.2-2' }}
</div> 


<div
style="
  color: #293AC6;
  font-weight: 600;
  position: absolute;
  top: 34.7%; 
  left: 109%;
  transform: translate(-50%, -50%); 
  z-index: 10; 
"
>

{{ vapour_pressure1ap | number: '1.2-2' }}
</div> 

<div
style="
  color: #00A578;
  font-weight: 600;
  position: absolute;
  top: 34.7%; 
  left: 114%;
  transform: translate(-50%, -50%); 
  z-index: 10; 
"
>
{{ vapour_pressure1ao | number: '1.2-2' }}
</div> 





<div
style="
  color: #D90606;
  font-weight: 600;
  position: absolute;
  top: 39.7%; 
  left: 104%;
  transform: translate(-50%, -50%); 
  z-index: 10; 
"
>
{{ vapour_temp1a | number: '1.2-2' }}
</div> 

<div
style="
  color: #00A578;
  font-weight: 600;
  position: absolute;
  top: 39.7%; 
  left: 114%;
  transform: translate(-50%, -50%); 
  z-index: 10; 
"
>
{{ vapour_temp1ao | number: '1.2-2' }}
</div> 

<div
style="
  color: #293AC6;
  font-weight: 600;
  position: absolute;
  top: 39.7%; 
  left: 109%;
  transform: translate(-50%, -50%); 
  z-index: 10; 
"
>
{{ vapour_temp1ap | number: '1.2-2' }}
</div> 



<div
style="
  color: #D90606;
  font-weight: 600;
  position: absolute;
  top: 44.7%; 
  left: 104%;
  transform: translate(-50%, -50%); 
  z-index: 10; 
"
>
{{ vapour_flow1a | number: '1.2-2' }}
</div> 

<div
style="
  color: #00A578;
  font-weight: 600;
  position: absolute;
  top: 44.7%; 
  left: 114%;
  transform: translate(-50%, -50%); 
  z-index: 10; 
"
>
{{ vapour_flow1ao | number: '1.2-2' }}
</div>

<div
style="
  color: #293AC6;
  font-weight: 600;
  position: absolute;
  top: 44.7%; 
  left: 109%;
  transform: translate(-50%, -50%); 
  z-index: 10; 
"
>
{{ vapour_flow1ap | number: '1.2-2' }}
</div>




<div
style="
  color: #D90606;
  font-weight: 600;
  position: absolute;
  top: 65.7%; 
  left: 104%;
  transform: translate(-50%, -50%); 
  z-index: 10; 
"
>
{{ bl_flow1a | number: '1.2-2' }}
</div> 

<div
style="
  color: #00A578;
  font-weight: 600;
  position: absolute;
  top: 65.7%; 
  left: 114%;
  transform: translate(-50%, -50%); 
  z-index: 10; 
"
>
{{ bl_flow1ao | number: '1.2-2' }}
</div> 

<div
style="
  color: #293AC6;
  font-weight: 600;
  position: absolute;
  top: 65.7%; 
  left: 109%;
  transform: translate(-50%, -50%); 
  z-index: 10; 
"
>
{{ bl_flow1ap | number: '1.2-2' }}
</div> 


<div
style="
  color: #D90606;
  font-weight: 600;
  position: absolute;
  top: 70.7%; 
  left: 104%;
  transform: translate(-50%, -50%); 
  z-index: 10; 
"
>
{{ bl_solid1a | number: '1.2-2' }}
</div> 

<div
style="
  color: #00A578;
  font-weight: 600;
  position: absolute;
  top: 70.7%; 
  left: 114%;
  transform: translate(-50%, -50%); 
  z-index: 10; 
"
>
{{ bl_solid1ao | number: '1.2-2' }}
</div> 

<div
style="
  color: #293AC6;
  font-weight: 600;
  position: absolute;
  top: 70.7%; 
  left: 109%;
  transform: translate(-50%, -50%); 
  z-index: 10; 
"
>
{{ bl_solid1ap | number: '1.2-2' }}
</div> 

<div
style="
  color: #D90606;
  font-weight: 600;
  position: absolute;
  top: 65.7%; 
  left: 150%;
  transform: translate(-50%, -50%); 
  z-index: 10; 
"
>
{{ bl_flow1b | number: '1.2-2' }}
</div> 

<div
style="
  color: #00A578;
  font-weight: 600;
  position: absolute;
  top: 65.7%; 
  left: 160%;
  transform: translate(-50%, -50%); 
  z-index: 10; 
"
>
{{ bl_flow1bo | number: '1.2-2' }}
</div> 

<div
style="
  color: #293AC6;
  font-weight: 600;
  position: absolute;
  top: 65.7%; 
  left: 155%;
  transform: translate(-50%, -50%); 
  z-index: 10; 
"
>
{{ bl_flow1bp | number: '1.2-2' }}
</div> 



<div
style="
  color: #D90606;
  font-weight: 600;
  position: absolute;
  top: 70.7%; 
  left: 150%;
  transform: translate(-50%, -50%); 
  z-index: 10; 
"
>
{{ bl_solid1b | number: '1.2-2' }}
</div> 

<div
style="
  color: #00A578;
  font-weight: 600;
  position: absolute;
  top: 70.7%; 
  left: 160%;
  transform: translate(-50%, -50%); 
  z-index: 10; 
"
>
{{ bl_solid1bo | number: '1.2-2' }}
</div> 

<div
style="
  color: #293AC6;
  font-weight: 600;
  position: absolute;
  top: 70.7%; 
  left: 155%;
  transform: translate(-50%, -50%); 
  z-index: 10; 
"
>
{{ bl_solid1bp | number: '1.2-2' }}
</div> 

<div
style="
  color: #D90606;
  font-weight: 600;
  position: absolute;
  top: 34.7%; 
  left: 149%;
  transform: translate(-50%, -50%); 
  z-index: 10; 
"
>
{{ vapour_pressure1b | number: '1.2-2' }}
</div> 

<div
style="
  color: #00A578;
  font-weight: 600;
  position: absolute;
  top: 34.7%; 
  left: 159%;
  transform: translate(-50%, -50%); 
  z-index: 10; 
"
>
{{ vapour_pressure1bo | number: '1.2-2' }}
</div> 

<div
style="
  color: #293AC6;
  font-weight: 600;
  position: absolute;
  top: 34.7%; 
  left: 154%;
  transform: translate(-50%, -50%); 
  z-index: 10; 
"
>
{{ vapour_pressure1bp | number: '1.2-2' }}
</div> 



<div
style="
  color: #D90606;
  font-weight: 600;
  position: absolute;
  top: 39.7%; 
  left: 149%;
  transform: translate(-50%, -50%); 
  z-index: 10; 
"
>
{{ vapour_temp1b | number: '1.2-2' }}
</div> 

<div
style="
  color: #00A578;
  font-weight: 600;
  position: absolute;
  top: 39.7%; 
  left: 159%;
  transform: translate(-50%, -50%); 
  z-index: 10; 
"
>
{{ vapour_temp1bo | number: '1.2-2' }}
</div> 

<div
style="
  color: #293AC6;
  font-weight: 600;
  position: absolute;
  top: 39.7%; 
  left: 154%;
  transform: translate(-50%, -50%); 
  z-index: 10; 
"
>
{{ vapour_temp1bp | number: '1.2-2' }}
</div> 


<div
style="
  color: #D90606;
  font-weight: 600;
  position: absolute;
  top: 44.7%; 
  left: 149%;
  transform: translate(-50%, -50%); 
  z-index: 10; 
"
>
{{ vapour_flow1b | number: '1.2-2' }}
</div> 

<div
style="
  color: #00A578;
  font-weight: 600;
  position: absolute;
  top: 44.7%; 
  left: 159%;
  transform: translate(-50%, -50%); 
  z-index: 10; 
"
>
{{ vapour_flow1bo | number: '1.2-2' }}
</div> 

<div
style="
  color: #293AC6;
  font-weight: 600;
  position: absolute;
  top: 44.7%; 
  left: 154%;
  transform: translate(-50%, -50%); 
  z-index: 10; 
"
>
{{ vapour_flow1bp | number: '1.2-2' }}
</div> 


   <!-- <div style="position: absolute; top: 52%; left: 60.5%">
      <app-heavy-liquor
        [hl_density]="
          responseData
            ? (
                ((responseData['1a BL Out Solid %'] + 1.337) * 6.5 +
                  1007 -
                  0.495 * 116.7) /
                1000
              ).toFixed(3)
            : ''
        "
        [hl_conc]="
          responseData
            ? (responseData['1a BL Out Solid %'] + 1.337).toFixed(2)
            : ''
        "
      >
      </app-heavy-liquor>
    </div> -->
    <!-- <div style="position: absolute; top: 30%; left: 93%">
      <app-vapour
        [vapour_pressure]="
          responseData ? responseData['1a PV Out Pr'].toFixed(2) : ''
        "
        [vapour_temp]="
          responseData ? responseData['1a PV Out Temp'].toFixed(2) : ''
        "
        [vapour_flow]="
          responseData ? responseData['1a PV Out Flow'].toFixed(2) : ''
        "
      ></app-vapour>
    </div>
    <div style="position: absolute; top: 30%; left: 136%">
      <app-vapour
        [vapour_pressure]="
          responseData ? responseData['1b PV Out Pr'].toFixed(2) : ''
        "
        [vapour_temp]="
          responseData ? responseData['1b PV Out Temp'].toFixed(2) : ''
        "
        [vapour_flow]="
          responseData ? responseData['1b PV Out Flow'].toFixed(2) : ''
        "
      ></app-vapour>
    </div>
    <div style="position: absolute; top: 68%; left: 88.5%">
      <app-black-liquor
        [bl_flow]="
          responseData ? responseData['1a BL Out Flow'].toFixed(2) : ''
        "
        [bl_solid]="
          responseData ? responseData['1a BL Out Solid %'].toFixed(2) : ''
        "
      >
      </app-black-liquor>
    </div>
    <div style="position: absolute; top: 68%; left: 132.5%">
      <app-black-liquor
        [bl_flow]="
          responseData ? responseData['1b BL Out Flow'].toFixed(2) : ''
        "
        [bl_solid]="
          responseData ? responseData['1b BL Out Solid %'].toFixed(2) : ''
        "
      >
      </app-black-liquor>
    </div> -->


  </div>



  <div
    *ngIf="screen[1]"
    style="position: absolute; top: 3%; left: 6%; height: 500px; width: 1000px"
  >
    <img
      src="../../assets/slider/2 3.svg"
      class="evaporator_2_3"
      *ngIf="screen[1]"
    />

    <div
style="
  color: #D90606;
  font-weight: 600;
  position: absolute;
  top: 113.7%; 
  left: 154%;
  transform: translate(-50%, -50%); 
  z-index: 10; 
"
>
{{ flow | number: '1.2-2' }}
</div> 

<div
style="
  color: #00A578;
  font-weight: 600;
  position: absolute;
  top: 113.7%; 
  left: 164%;
  transform: translate(-50%, -50%); 
  z-index: 10; 
"
>
{{ flowo | number: '1.2-2' }}
</div> 

<div
style="
  color: #293AC6;
  font-weight: 600;
  position: absolute;
  top: 113.7%; 
  left: 159%;
  transform: translate(-50%, -50%); 
  z-index: 10; 
"
>
{{ flowp | number: '1.2-2' }}
</div> 


    <!-- <div style="position: absolute; top: 111%; left: 151.5%">
      <app-green-flow
        [flow]="responseData ? responseData['SC Total Flow'].toFixed(2) : ''"
      >
      </app-green-flow>
    </div> -->
 
  </div>


  <div
    *ngIf="screen[2]"
    style="position: absolute; top: 3%; left: 6%; height: 500px; width: 1000px"
  >
    <img src="../../assets/slider/3 2.svg" class="evaporator_2_3" />

    <div
style="
  color: #D90606;
  font-weight: 600;
  position: absolute;
  top: 35.7%; 
  left: 54%;
  transform: translate(-50%, -50%); 
  z-index: 10; 
"
>
{{ vapour_pressure2 | number: '1.2-2' }}
</div> 

<div
style="
  color: #00A578;
  font-weight: 600;
  position: absolute;
  top: 35.7%; 
  left: 64%;
  transform: translate(-50%, -50%); 
  z-index: 10; 
"
>
{{ vapour_pressure2o | number: '1.2-2' }}
</div> 

<div
style="
  color: #293AC6;
  font-weight: 600;
  position: absolute;
  top: 35.7%; 
  left: 59%;
  transform: translate(-50%, -50%); 
  z-index: 10; 
"
>
{{ vapour_pressure2p | number: '1.2-2' }}
</div> 



<div
style="
  color: #D90606;
  font-weight: 600;
  position: absolute;
  top: 40.7%; 
  left: 54%;
  transform: translate(-50%, -50%); 
  z-index: 10; 
"
>
{{ vapour_temp2 | number: '1.2-2' }}
</div> 

<div
style="
  color: #00A578;
  font-weight: 600;
  position: absolute;
  top: 40.7%; 
  left: 64%;
  transform: translate(-50%, -50%); 
  z-index: 10; 
"
>
{{ vapour_temp2o | number: '1.2-2' }}
</div> 

<div
style="
  color: #293AC6;
  font-weight: 600;
  position: absolute;
  top: 40.7%; 
  left: 59%;
  transform: translate(-50%, -50%); 
  z-index: 10; 
"
>
{{ vapour_temp2p | number: '1.2-2' }}
</div> 


<div
style="
  color: #D90606;
  font-weight: 600;
  position: absolute;
  top: 44.7%; 
  left: 54%;
  transform: translate(-50%, -50%); 
  z-index: 10; 
"
>
{{ vapour_flow2 | number: '1.2-2' }}
</div> 

<div
style="
  color: #00A578;
  font-weight: 600;
  position: absolute;
  top: 44.7%; 
  left: 64%;
  transform: translate(-50%, -50%); 
  z-index: 10; 
"
>
{{ vapour_flow2o | number: '1.2-2' }}
</div> 

<div
style="
  color: #293AC6;
  font-weight: 600;
  position: absolute;
  top: 44.7%; 
  left: 59%;
  transform: translate(-50%, -50%); 
  z-index: 10; 
"
>
{{ vapour_flow2p | number: '1.2-2' }}
</div> 



<div
style="
  color: #D90606;
  font-weight: 600;
  position: absolute;
  top: 66.7%; 
  left: 56%;
  transform: translate(-50%, -50%); 
  z-index: 10; 
"
>
{{ bl_flow2 | number: '1.2-2' }}
</div> 

<div
style="
  color: #00A578;
  font-weight: 600;
  position: absolute;
  top: 66.7%; 
  left: 66%;
  transform: translate(-50%, -50%); 
  z-index: 10; 
"
>
{{ bl_flow2o | number: '1.2-2' }}
</div> 

<div
style="
  color: #293AC6;
  font-weight: 600;
  position: absolute;
  top: 66.7%; 
  left: 61%;
  transform: translate(-50%, -50%); 
  z-index: 10; 
"
>
{{ bl_flow2p | number: '1.2-2' }}
</div> 


<div
style="
  color: #D90606;
  font-weight: 600;
  position: absolute;
  top: 71.7%; 
  left: 56%;
  transform: translate(-50%, -50%); 
  z-index: 10; 
"
>
{{ bl_solid2 | number: '1.2-2' }}
</div>

<div
style="
  color: #00A578;
  font-weight: 600;
  position: absolute;
  top: 71.7%; 
  left: 66%;
  transform: translate(-50%, -50%); 
  z-index: 10; 
"
>
{{ bl_solid2o | number: '1.2-2' }}
</div>


<div
style="
  color: #293AC6;
  font-weight: 600;
  position: absolute;
  top: 71.7%; 
  left: 61%;
  transform: translate(-50%, -50%); 
  z-index: 10; 
"
>
{{ bl_solid2p | number: '1.2-2' }}
</div>


<div
style="
  color: #D90606;
  font-weight: 600;
  position: absolute;
  top: 35.7%; 
  left: 142%;
  transform: translate(-50%, -50%); 
  z-index: 10; 
"
>
{{ vapour_pressure3 | number: '1.2-2' }}
</div> 

<div
style="
  color: #00A578;
  font-weight: 600;
  position: absolute;
  top: 35.7%; 
  left: 152%;
  transform: translate(-50%, -50%); 
  z-index: 10; 
"
>
{{ vapour_pressure3o | number: '1.2-2' }}
</div>

<div
style="
  color: #293AC6;
  font-weight: 600;
  position: absolute;
  top: 35.7%; 
  left: 147%;
  transform: translate(-50%, -50%); 
  z-index: 10; 
"
>
{{ vapour_pressure3p | number: '1.2-2' }}
</div>


<div
style="
  color: #D90606;
  font-weight: 600;
  position: absolute;
  top: 40.7%; 
  left: 142%;
  transform: translate(-50%, -50%); 
  z-index: 10; 
"
>
{{ vapour_temp3 | number: '1.2-2' }}
</div> 

<div
style="
  color: #00A578;
  font-weight: 600;
  position: absolute;
  top: 40.7%; 
  left: 152%;
  transform: translate(-50%, -50%); 
  z-index: 10; 
"
>
{{ vapour_temp3o | number: '1.2-2' }}
</div> 

<div
style="
  color: #293AC6;
  font-weight: 600;
  position: absolute;
  top: 40.7%; 
  left: 147%;
  transform: translate(-50%, -50%); 
  z-index: 10; 
"
>
{{ vapour_temp3p | number: '1.2-2' }}
</div> 


<div
style="
  color: #D90606;
  font-weight: 600;
  position: absolute;
  top: 44.7%; 
  left: 142%;
  transform: translate(-50%, -50%); 
  z-index: 10; 
"
>
{{ vapour_flow3 | number: '1.2-2' }}
</div> 

<div
style="
  color: #00A578;
  font-weight: 600;
  position: absolute;
  top: 44.7%; 
  left: 152%;
  transform: translate(-50%, -50%); 
  z-index: 10; 
"
>
{{ vapour_flow3o | number: '1.2-2' }}
</div> 

<div
style="
  color: #293AC6;
  font-weight: 600;
  position: absolute;
  top: 44.7%; 
  left: 147%;
  transform: translate(-50%, -50%); 
  z-index: 10; 
"
>
{{ vapour_flow3p | number: '1.2-2' }}
</div> 

<div
style="
  color: #D90606;
  font-weight: 600;
  position: absolute;
  top: 66.7%; 
  left: 144%;
  transform: translate(-50%, -50%); 
  z-index: 10; 
"
>
{{ bl_flow3 | number: '1.2-2' }}
</div> 

<div
style="
  color: #00A578;
  font-weight: 600;
  position: absolute;
  top: 66.7%; 
  left: 154%;
  transform: translate(-50%, -50%); 
  z-index: 10; 
"
>
{{ bl_flow3o | number: '1.2-2' }}
</div> 

<div
style="
  color: #293AC6;
  font-weight: 600;
  position: absolute;
  top: 66.7%; 
  left: 149%;
  transform: translate(-50%, -50%); 
  z-index: 10; 
"
>
{{ bl_flow3p | number: '1.2-2' }}
</div> 


<div
style="
  color: #D90606;
  font-weight: 600;
  position: absolute;
  top: 71.7%; 
  left: 144%;
  transform: translate(-50%, -50%); 
  z-index: 10; 
"
>
{{ bl_solid3 | number: '1.2-2' }}
</div>

<div
style="
  color: #00A578;
  font-weight: 600;
  position: absolute;
  top: 71.7%; 
  left: 154%;
  transform: translate(-50%, -50%); 
  z-index: 10; 
"
>
{{ bl_solid3o | number: '1.2-2' }}
</div>

<div
style="
  color: #293AC6;
  font-weight: 600;
  position: absolute;
  top: 71.7%; 
  left: 149%;
  transform: translate(-50%, -50%); 
  z-index: 10; 
"
>
{{ bl_solid3p | number: '1.2-2' }}
</div>



    <!-- <div style="position: absolute; top: 30%; left: 42%">
      <app-vapour
        [vapour_pressure]="
          responseData ? responseData['2 PV Out Pr'].toFixed(2) : ''
        "
        [vapour_temp]="
          responseDatatrue ? responseData['2 PV Out Temp'].toFixed(2) : ''
        "
        [vapour_flow]="
          responseData ? responseData['2 PV Out Flow'].toFixed(2) : ''
        "
      ></app-vapour>
    </div>
    <div style="position: absolute; top: 30%; left: 129%">
      <app-vapour
        [vapour_pressure]="
          responseData ? responseData['3 PV Out Pr'].toFixed(2) : ''
        "
        [vapour_temp]="
          responseData ? responseData['3 PV Out Temp'].toFixed(2) : ''
        "
        [vapour_flow]="
          responseData ? responseData['3 PV Out Flow'].toFixed(2) : ''
        "
      ></app-vapour>
    </div>
    <div style="position: absolute; top: 67%; left: 38.5%">
      <app-black-liquor
        [bl_flow]="responseData ? responseData['2 BL Out Flow'].toFixed(2) : ''"
        [bl_solid]="
          responseData ? responseData['2 BL Out Solid %'].toFixed(2) : ''
        "
      >
      </app-black-liquor>
    </div>
    <div style="position: absolute; top: 67%; left: 126.5%">
      <app-black-liquor
        [bl_flow]="responseData ? responseData['3 BL Out Flow'].toFixed(2) : ''"
        [bl_solid]="
          responseData ? responseData['3 BL Out Solid %'].toFixed(2) : ''
        "
      >
      </app-black-liquor>
    </div> -->
 
  </div>
  <div
    *ngIf="screen[3]"
    style="position: absolute; top: 3%; left: 6%; height: 500px; width: 1000px"
  >
    <img src="../../assets/slider/4.svg" class="evaporator_2_3" />

    <div
    style="
      color: #D90606;
      font-weight: 600;
      position: absolute;
      top: 28%; 
      left: 57%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{ vapour_pressure4 | number: '1.2-2' }}
    </div> 
    <div
    style="
      color: #D90606;
      font-weight: 600;
      position: absolute;
      top: 33%; 
      left: 57%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{ vapour_temp4 | number: '1.2-2' }}
    </div> 
    <div
    style="
      color: #D90606;
      font-weight: 600;
      position: absolute;
      top: 38%; 
      left: 57%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{ vapour_flow4 | number: '1.2-2' }}
    </div> 

    <div
    style="
      color: #00A578;
      font-weight: 600;
      position: absolute;
      top: 28%; 
      left: 65%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{ vapour_pressure4o | number: '1.2-2' }}
    </div> 
    <div
    style="
      color: #00A578;
      font-weight: 600;
      position: absolute;
      top: 33%; 
      left: 65%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{ vapour_temp4o | number: '1.2-2' }}
    </div> 
    <div
    style="
      color: #00A578;
      font-weight: 600;
      position: absolute;
      top: 38%; 
      left: 65%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{ vapour_flow4o | number: '1.2-2' }}
    </div> 

    <div
    style="
      color: #293AC6;
      font-weight: 600;
      position: absolute;
      top: 28%; 
      left: 61%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{ vapour_pressure4p | number: '1.2-2' }}
    </div> 
    <div
    style="
      color: #293AC6;
      font-weight: 600;
      position: absolute;
      top: 33%; 
      left: 61%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{ vapour_temp4p | number: '1.2-2' }}
    </div> 
    <div
    style="
      color: #293AC6;
      font-weight: 600;
      position: absolute;
      top: 38%; 
      left: 61%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{ vapour_flow4p | number: '1.2-2' }}
    </div> 
    
    <div
    style="
      color: #D90606;
      font-weight: 600;
      position: absolute;
      top: 61%; 
      left: 59%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{ bl_flow4 | number: '1.2-2' }}
    </div> 
    <div
    style="
      color: #D90606;
      font-weight: 600;
      position: absolute;
      top: 66%; 
      left: 59%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{ bl_solid4 | number: '1.2-2' }}
    </div>

    <div
    style="
      color: #00A578;
      font-weight: 600;
      position: absolute;
      top: 61%; 
      left: 67%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{ bl_flow4o | number: '1.2-2' }}
    </div> 
    <div
    style="
      color: #00A578;
      font-weight: 600;
      position: absolute;
      top: 66%; 
      left: 67%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{ bl_solid4o | number: '1.2-2' }}
    </div>

    <div
    style="
      color: #293AC6;
      font-weight: 600;
      position: absolute;
      top: 61%; 
      left: 63%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{ bl_flow4p | number: '1.2-2' }}
    </div> 
    <div
    style="
      color: #293AC6;
      font-weight: 600;
      position: absolute;
      top: 66%; 
      left: 63%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{ bl_solid4p | number: '1.2-2' }}
    </div>
    
    <div
    style="
      color: #D90606;
      font-weight: 600;
      position: absolute;
      top: 28%; 
      left: 153%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{ vapour_pressure5 | number: '1.2-2' }}
    </div> 
    <div
    style="
      color: #D90606;
      font-weight: 600;
      position: absolute;
      top: 33%; 
      left: 153%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{ vapour_temp5 | number: '1.2-2' }}
    </div> 
    <div
    style="
      color: #D90606;
      font-weight: 600;
      position: absolute;
      top: 38%; 
      left: 153%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{ vapour_flow5 | number: '1.2-2' }}
    </div> 


    <div
    style="
      color: #00A578;
      font-weight: 600;
      position: absolute;
      top: 28%; 
      left: 161%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{ vapour_pressure5o | number: '1.2-2' }}
    </div> 
    <div
    style="
      color: #00A578;
      font-weight: 600;
      position: absolute;
      top: 33%; 
      left: 161%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{ vapour_temp5o | number: '1.2-2' }}
    </div> 
    <div
    style="
      color: #00A578;
      font-weight: 600;
      position: absolute;
      top: 38%; 
      left: 161%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{ vapour_flow5o | number: '1.2-2' }}
    </div> 


    <div
    style="
      color: #293AC6;
      font-weight: 600;
      position: absolute;
      top: 28%; 
      left: 157%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{ vapour_pressure5p | number: '1.2-2' }}
    </div> 
    <div
    style="
      color: #293AC6;
      font-weight: 600;
      position: absolute;
      top: 33%; 
      left: 157%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{ vapour_temp5p | number: '1.2-2' }}
    </div> 
    <div
    style="
      color: #293AC6;
      font-weight: 600;
      position: absolute;
      top: 38%; 
      left: 157%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{ vapour_flow5p | number: '1.2-2' }}
    </div> 
    
    <div
    style="
      color: #D90606;
      font-weight: 600;
      position: absolute;
      top: 61%; 
      left: 155%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{ bl_flow5 | number: '1.2-2' }}
    </div> 
    <div
    style="
      color: #D90606;
      font-weight: 600;
      position: absolute;
      top: 66%; 
      left: 155%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{ bl_solid5 | number: '1.2-2' }}
    </div>

    <div
    style="
      color: #00A578;
      font-weight: 600;
      position: absolute;
      top: 61%; 
      left: 163%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{ bl_flow5o | number: '1.2-2' }}
    </div> 
    <div
    style="
      color: #00A578;
      font-weight: 600;
      position: absolute;
      top: 66%; 
      left: 163%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{ bl_solid5o | number: '1.2-2' }}
    </div>

    <div
    style="
      color: #293AC6;
      font-weight: 600;
      position: absolute;
      top: 61%; 
      left: 159%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{ bl_flow5p | number: '1.2-2' }}
    </div> 
    <div
    style="
      color: #293AC6;
      font-weight: 600;
      position: absolute;
      top: 66%; 
      left: 159%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{ bl_solid5p | number: '1.2-2' }}
    </div>
    <!-- <div style="position: absolute; top: 24%; left: 44%">
      <app-vapour
        [vapour_pressure]="
          responseData ? responseData['4 PV Out Pr'].toFixed(2) : ''
        "
        [vapour_temp]="
          responseData ? responseData['4 PV Out Temp'].toFixed(2) : ''
        "
        [vapour_flow]="
          responseData ? responseData['4 PV Out Flow'].toFixed(2) : ''
        "
      ></app-vapour>
    </div>
    <div style="position: absolute; top: 24%; left: 140%">
      <app-vapour
        [vapour_pressure]="
          responseData ? responseData['5 PV Out Pr'].toFixed(2) : ''
        "
        [vapour_temp]="
          responseData ? responseData['5 PV Out Temp'].toFixed(2) : ''
        "
        [vapour_flow]="
          responseData ? responseData['5 PV Out Flow'].toFixed(2) : ''
        "
      ></app-vapour>
    </div>
    <div style="position: absolute; top: 64%; left: 41.5%">
      <app-black-liquor
        [bl_flow]="responseData ? responseData['4 BL Out Flow'].toFixed(2) : ''"
        [bl_solid]="
          responseData ? responseData['4 BL Out Solid %'].toFixed(2) : ''
        "
      >
      </app-black-liquor>
    </div>
    <div style="position: absolute; top: 64%; left: 138.5%">
      <app-black-liquor
        [bl_flow]="responseData ? responseData['5 BL Out Flow'].toFixed(2) : ''"
        [bl_solid]="
          responseData ? responseData['5 BL Out Solid %'].toFixed(2) : ''
        "
      >
      </app-black-liquor>
    </div> -->
  
  </div>
  <div
    *ngIf="screen[4]"
    style="position: absolute; top: 3%; left: 6%; height: 500px; width: 1000px"
  >
    <img src="../../assets/slider/5.svg" class="evaporator_2_3" />

    <div
    style="
      color: #D90606;
      font-weight: 600;
      position: absolute;
      top: 32%; 
      left: 65%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{ vapour_pressure6 | number: '1.2-2' }}
    </div> 
    <div
    style="
      color: #D90606;
      font-weight: 600;
      position: absolute;
      top: 37%; 
      left: 65%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{ vapour_temp6 | number: '1.2-2' }}
    </div> 
    <div
    style="
      color: #D90606;
      font-weight: 600;
      position: absolute;
      top: 42%; 
      left: 65%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{ vapour_flow6 | number: '1.2-2' }}
    </div> 


    <div
    style="
      color: #00A578;
      font-weight: 600;
      position: absolute;
      top: 32%; 
      left: 73%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{ vapour_pressure6o | number: '1.2-2' }}
    </div> 
    <div
    style="
      color: #00A578;
      font-weight: 600;
      position: absolute;
      top: 37%; 
      left: 73%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{ vapour_temp6o | number: '1.2-2' }}
    </div> 
    <div
    style="
      color: #00A578;
      font-weight: 600;
      position: absolute;
      top: 42%; 
      left: 73%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{ vapour_flow6o | number: '1.2-2' }}
    </div> 

    <div
    style="
      color: #293AC6;
      font-weight: 600;
      position: absolute;
      top: 32%; 
      left: 69%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{ vapour_pressure6p | number: '1.2-2' }}
    </div> 
    <div
    style="
      color: #293AC6;
      font-weight: 600;
      position: absolute;
      top: 37%; 
      left: 69%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{ vapour_temp6p | number: '1.2-2' }}
    </div> 
    <div
    style="
      color: #293AC6;
      font-weight: 600;
      position: absolute;
      top: 42%; 
      left: 69%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{ vapour_flow6p | number: '1.2-2' }}
    </div> 
    
    
    <div
    style="
      color: #D90606;
      font-weight: 600;
      position: absolute;
      top: 65%; 
      left: 66%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{ bl_flow6 | number: '1.2-2' }}
    </div> 
    <div
    style="
      color: #D90606;
      font-weight: 600;
      position: absolute;
      top: 71%; 
      left: 66%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{ bl_solid6 | number: '1.2-2' }}
    </div>

    <div
    style="
      color: #00A578;
      font-weight: 600;
      position: absolute;
      top: 65%; 
      left: 75%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{ bl_flow6o | number: '1.2-2' }}
    </div> 
    <div
    style="
      color: #00A578;
      font-weight: 600;
      position: absolute;
      top: 71%; 
      left: 75%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{ bl_solid6o | number: '1.2-2' }}
    </div>

    <div
    style="
      color: #293AC6;
      font-weight: 600;
      position: absolute;
      top: 65%; 
      left: 71%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{ bl_flow6p | number: '1.2-2' }}
    </div> 
    <div
    style="
      color: #293AC6;
      font-weight: 600;
      position: absolute;
      top: 71%; 
      left: 71%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{ bl_solid6p | number: '1.2-2' }}
    </div>

    <div
    style="
      color: #D90606;
      font-weight: 600;
      position: absolute;
      top: 32%; 
      left: 110%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{ vapour_pressure7 | number: '1.2-2' }}
    </div> 
    <div
    style="
      color: #D90606;
      font-weight: 600;
      position: absolute;
      top: 37%; 
      left: 110%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{ vapour_temp7 | number: '1.2-2' }}
    </div> 
    <div
    style="
      color: #D90606;
      font-weight: 600;
      position: absolute;
      top: 42%; 
      left: 110%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{ vapour_flow7 | number: '1.2-2' }}
    </div> 
    

    <div
    style="
      color: #00A578;
      font-weight: 600;
      position: absolute;
      top: 32%; 
      left: 118%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{ vapour_pressure7o | number: '1.2-2' }}
    </div> 
    <div
    style="
      color: #00A578;
      font-weight: 600;
      position: absolute;
      top: 37%; 
      left: 118%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{ vapour_temp7o | number: '1.2-2' }}
    </div> 
    <div
    style="
      color: #00A578;
      font-weight: 600;
      position: absolute;
      top: 42%; 
      left: 118%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{ vapour_flow7o | number: '1.2-2' }}
    </div> 

    <div
    style="
      color: #293AC6;
      font-weight: 600;
      position: absolute;
      top: 32%; 
      left: 114%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{ vapour_pressure7p | number: '1.2-2' }}
    </div> 
    <div
    style="
      color: #293AC6;
      font-weight: 600;
      position: absolute;
      top: 37%; 
      left: 114%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{ vapour_temp7p | number: '1.2-2' }}
    </div> 
    <div
    style="
      color: #293AC6;
      font-weight: 600;
      position: absolute;
      top: 42%; 
      left: 114%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{ vapour_flow7p | number: '1.2-2' }}
    </div> 


    <div
    style="
      color: #D90606;
      font-weight: 600;
      position: absolute;
      top: 65%; 
      left: 112%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{ bl_flow7 | number: '1.2-2' }}
    </div> 
    <div
    style="
      color: #D90606;
      font-weight: 600;
      position: absolute;
      top: 71%; 
      left: 112%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{ bl_solid7 | number: '1.2-2' }}
    </div>

    <div
    style="
      color: #00A578;
      font-weight: 600;
      position: absolute;
      top: 65%; 
      left: 120%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{ bl_flow7o | number: '1.2-2' }}
    </div> 
    <div
    style="
      color: #00A578;
      font-weight: 600;
      position: absolute;
      top: 71%; 
      left: 120%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{ bl_solid7o | number: '1.2-2' }}
    </div>

    <div
    style="
      color: #293AC6;
      font-weight: 600;
      position: absolute;
      top: 65%; 
      left: 116%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{ bl_flow7p | number: '1.2-2' }}
    </div> 
    <div
    style="
      color: #293AC6;
      font-weight: 600;
      position: absolute;
      top: 71%; 
      left: 116%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{ bl_solid7p | number: '1.2-2' }}
    </div>

    <div
    style="
      color: #D90606;
      font-weight: 600;
      position: absolute;
      top: 113%; 
      left: 111%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{ secflow | number: '1.2-2' }}
    </div>

    <div
    style="
      color: #00A578;
      font-weight: 600;
      position: absolute;
      top: 113%; 
      left: 119%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{secflowo | number: '1.2-2' }}
    </div>

    <div
    style="
      color: #293AC6;
      font-weight: 600;
      position: absolute;
      top: 113%; 
      left: 115%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{secflowp | number: '1.2-2' }}
    </div>

    <div
    style="
      color: #00A578;
      font-weight: 600;
      position: absolute;
      top: 71%; 
      left: 120%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{ bl_solid7o | number: '1.2-2' }}
    </div>

    <!-- <div
    style="
      color: #293AC6;
      font-weight: 600;
      position: absolute;
      top: 71%; 
      left: 120%;
      transform: translate(-50%, -50%); 
      z-index: 10; 
    "
    >
    {{ bl_solid7p | number: '1.2-2' }}
    </div> -->
    <!-- <div style="position: absolute; top: 19%; left: 52%">
      <app-vapour
        [vapour_pressure]="
          responseData ? responseData['6 PV Out Pr'].toFixed(2) : ''
        "
        [vapour_temp]="
          responseData ? responseData['6 PV Out Temp'].toFixed(2) : ''
        "
        [vapour_flow]="
          responseData ? responseData['6 PV Out Flow'].toFixed(2) : ''
        "
      ></app-vapour>
    </div>
    <div style="position: absolute; top: 19%; left: 99%">
      <app-vapour
        [vapour_pressure]="
          responseData ? responseData['7 PV Out Pr'].toFixed(2) : ''
        "
        [vapour_temp]="
          responseData ? responseData['7 PV Out Temp'].toFixed(2) : ''
        "
        [vapour_flow]="
          responseData ? responseData['7 PV Out Flow'].toFixed(2) : ''
        "
      ></app-vapour>
    </div>
    <div style="position: absolute; top: 64%; left: 48.5%">
      <app-black-liquor
        [bl_flow]="responseData ? responseData['6 BL Out Flow'].toFixed(2) : ''"
        [bl_solid]="
          responseData ? responseData['6 BL Out Solid %'].toFixed(2) : ''
        "
      >
      </app-black-liquor>
    </div>
    <div style="position: absolute; top: 64%; left: 95.5%">
      <app-black-liquor
        [bl_flow]="responseData ? responseData['7 BL Out Flow'].toFixed(2) : ''"
        [bl_solid]="
          responseData ? responseData['7 BL Out Solid %'].toFixed(2) : ''
        "
      >
      </app-black-liquor>
    </div>
    <div style="position: absolute; top: 111%; left: 96.5%">
      <app-orange-flow
        [flow]="responseData ? responseData['PC Total Flow'].toFixed(2) : ''"
      >
      </app-orange-flow>
    </div> -->
  </div>
  <img
    src="../../assets/slider/arrow-left.png"
    class="arrow_left"
    (click)="redirectToPrev()"
    *ngIf="!screen[0]"
  />
  <img
    src="../../assets/slider/arrow-right.png"
    class="arrow_right"
    (click)="redirectToNext()"
    *ngIf="!screen[4]"
  />
</div>
