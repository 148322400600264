import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './authentication/authentication.guard';
import { EvaporatorComponent } from './components/evaporator/evaporator.component';
import { HomeScreenComponent } from './components/home-component/home-screen.component';
import { SodaRecoveryComponent } from './components/soda-recovery/soda-recovery.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: HomeScreenComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'soda-recovery',
    component: SodaRecoveryComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'evaporator', 
    component: EvaporatorComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
