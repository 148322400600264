import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-top-kpi',
  templateUrl: './top-kpi.component.html',
  styleUrls: ['./top-kpi.component.css']
})
export class TopKpiComponent {
  @Input() steam_BL: any;
  @Input() steam_economy: any;
  @Input() steam_flow: any;
  @Input() steam_wl: any;
  @Input() steam_economy_o: any;
  @Input() steam_red: any;
  @Input() steam_red_percent: any;
  @Input() fuel_red: any;
ngOnInit(){
  console.log(this.steam_BL);
  console.log(this.steam_economy);

  console.log(this.steam_wl);
  console.log(this.steam_economy_o);

  console.log(this.steam_red_percent);





}
  


}
