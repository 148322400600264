import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-vapour',
  templateUrl: './vapour.component.html',
  styleUrls: ['./vapour.component.css']
})
export class VapourComponent {
  @Input() vapour_pressure: any;
  @Input() vapour_temp: any;
  @Input() vapour_flow: any;
}
