
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-side-top-nav-bar',
  templateUrl: './side-top-nav-bar.component.html',
  styleUrls: ['./side-top-nav-bar.component.css']
})

export class SideTopNavBarComponent {
  @Input() title: any;
  @Input() dynamicPath: any;
  @Input() backPath: any;
  showOption: boolean = true;
  constructor(private router: Router) {}
  logout() {
    localStorage.removeItem('user');
    location.reload();
  }

  navigateBack() {
    if(this.backPath) {
      this.router.navigate([this.backPath]);
    } 
  }

  navigateToHome() {
    if(this.dynamicPath) {
      this.router.navigate([this.dynamicPath]);
    }
  }

  toggleDropdown() {
    this.showOption = false;
  }

  hideShowMenu(show: boolean) {
    if(show) {
      this.showOption = true;
    }
    else {
      this.showOption = false;
    }
  }

  dropdownOpen() {
    // code to show and hide dropdown menu.
  }
}

