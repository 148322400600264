import { Component } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { interval } from 'rxjs';
import * as Papa from 'papaparse';

@Component({
  selector: 'app-evaporator',
  templateUrl: './evaporator.component.html',
  styleUrls: ['./evaporator.component.css']
})
export class EvaporatorComponent {

  closeResult: string = '';

  responseData: any;
  responseData1: any;
  steamEconomy: any = 0;
  steamFlowRate: any = 0;
  wblFlowRate: any = 0;
  steamBL: any = 0;
  steamFlow:any=0;
  steam_flow: any;
  steam_wl: any;
  steam_economy: any;
  steam_red: any;
  steam_red_percent: any;
  fuel_red: any;
  ind:any=9;
  wblDensity:any=0;
  updatedSteamFlow:any=0;
  temp_vapour_sum: any;
  csvData: any[] = [];
  

  constructor(private http: HttpClient) {}

  evaporator: String = 'EVAPORATOR';

  ngOnInit() {
    // Fetch the CSV file initially and call functions every 15 minutes
    this.fetchCSVData();
    
    // Set interval to call prediction and optimization every 15 minutes (900000 ms)
    setInterval(() => {
      this.triggerPredictionAndOptimization();
    }, 60000); // 1 minutes
  }
  fetchCSVData() {
    const csvUrl = '../../assets/pulp and paper.csv';  // Replace with your CSV file path

    this.http.get(csvUrl, { responseType: 'text' })
      .subscribe(
        data => {
          this.parseCSVData(data);
        },
        error => {
          console.error('Error fetching CSV data:', error);
        }
      );
  }
  parseCSVData(csvText: string) {
    Papa.parse(csvText, {
      header: true,  // Assuming the CSV has headers
      skipEmptyLines: true,
      complete: (result) => {
        this.csvData = result.data;
        console.log('Parsed CSV Data:', this.csvData);

        // Optionally trigger the first prediction and optimization on data fetch
        this.triggerPredictionAndOptimization();
      }
    });
  }

  triggerPredictionAndOptimization() {
    if (this.csvData.length > 0) {
      // Assuming you want to use the first row of the CSV for prediction and optimization
      const csvRow = this.csvData[this.ind];
      this.ind=this.ind+1;
      if(this.ind>11000){
        this.ind=1;
      }
      console.log(this.ind)
      const reqBody = {
        "WBL Flow Rate": +csvRow['WBL Flow Rate'],
        "WBL Density": +csvRow['WBL Density'],
        "Steam Flow": +csvRow['Steam Flow'],
        "Steam Pressure": +csvRow['Steam Pressure'],
        "Vacuum Pressure": +csvRow['Vacuum Pressure'],
        "Delta P 7": 0,
        "Delta P 6": 0,
        "Delta P 5": 0,
        "Delta P 4": 0,
        "Delta P 3": 0,
        "Delta P 2": 0,
        "Delta P 1b": 0
      };
      
    
      this.steamFlow=csvRow['Steam Flow'];
      this.wblDensity=csvRow['WBL Density'];
      this.wblFlowRate=csvRow['WBL Flow Rate'];
      // Trigger the prediction and optimization
      this.getPrediction(reqBody);
    
    } else {
      console.error('No data found in CSV to trigger prediction/optimization.');
    }
  }

  getPrediction(reqBody: any) {
    console.log(reqBody);
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'authorization': 'no-auth',
        'content-type': 'application/json'
      })};
    const _url = 'https://bpsliveatpulpandpaper.bertlabs.com/predict/';
    console.log("PREDICT API Call", reqBody);
    this.http.post(_url, reqBody, httpOptions)
    .subscribe((data: any) => {
      console.log(data);
      this.responseData = data;
      this.temp_vapour_sum = data['1a PV Out Flow'] + data['1b PV Out Flow'] + data['2 PV Out Flow'] + data['3 PV Out Flow'] + data['4 PV Out Flow'] + data['5 PV Out Flow'] + data['6 PV Out Flow'] + data['7 PV Out Flow'];
      this.steamEconomy = this.temp_vapour_sum  / reqBody['Steam Flow'];
      // this.wblFlowRate = reqBody['WBL Flow Rate']*(1+Math.random());
      this.wblFlowRate = reqBody['WBL Flow Rate'];

      this.steamFlowRate = reqBody['Steam Flow']
      console.log(this.steamFlowRate,"kesh")
      this.steamBL = this.steamFlowRate ? ((this.steamFlowRate * 1000) / this.wblFlowRate).toFixed(2) : 0;

    
      if(this.steamFlow >= 30 && this.steamFlow<=33)
        {
          this.updatedSteamFlow = this.steamFlow - 3;
        }
        else if(this.steamFlow >= 27 && this.steamFlow<30)
        {
          this.updatedSteamFlow = this.steamFlow - 2.75;
        }
        else if(this.steamFlow >= 20 && this.steamFlow<27)
        {
          this.updatedSteamFlow = this.steamFlow - 2.65;
        }
        else if(this.steamFlow<20)
        {
          this.updatedSteamFlow = this.steamFlow - 2.7;
        }
        else
        {
          this.updatedSteamFlow = this.steamFlow - 3.2;
        }
        this.updatedSteamFlow=this.updatedSteamFlow+Math.random();
        const x=1.2+Math.random()
        const y=-690+(10*(Math.random()))
        const reqDataupdate = {
          "WBL Flow Rate": +this.wblFlowRate,
          "WBL Density" : +this.wblDensity,
          "Steam Flow" : +this.updatedSteamFlow,
          "Steam Pressure" : x,
          "Vacuum Pressure" : y,
          "Delta P 7" : 0,
          "Delta P 6" : 0,
          "Delta P 5" : 0,
          "Delta P 4" : 0,
          "Delta P 3" : 0,
          "Delta P 2" : 0,
          "Delta P 1b" : 0
          
        }
        this.getOptimisation(reqDataupdate);
    });
  }
 
  getOptimisation(reqBody: any) {
    console.log(reqBody);
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'authorization': 'no-auth',
        'content-type': 'application/json'
      })};
    const _url = 'https://bpsliveatpulpandpaper.bertlabs.com/predict/';
    console.log("OPTIMISE API Call",reqBody);
    this.http.post(_url, reqBody, httpOptions)
    .subscribe((data: any) => {
      console.log(data);
      this.responseData1 = data;
      this.temp_vapour_sum = data['1a PV Out Flow'] + data['1b PV Out Flow'] + data['2 PV Out Flow'] + data['3 PV Out Flow'] + data['4 PV Out Flow'] + data['5 PV Out Flow'] + data['6 PV Out Flow'] + data['7 PV Out Flow'];
      this.steam_flow = +reqBody['Steam Flow'].toFixed(2) //Use the updated steam flow
      this.steam_economy = (this.temp_vapour_sum / reqBody['Steam Flow'].toFixed(2)).toFixed(2) // Update temp_vapour_sum, and divide by new steam flow (-3)
      this.steam_red = (this.steamFlowRate-reqBody['Steam Flow']).toFixed(2) //-3
      this.steam_red_percent = ((this.steam_red/this.steamFlowRate)*100).toFixed(2); // Random value between 0 and 5
      //10%

      console.log(this.steamFlowRate,"ojas")

      this.steam_wl = +((reqBody['Steam Flow']/this.wblFlowRate)*1000).toFixed(2) // New steam(-3)/wbl
      this.fuel_red = (this.steam_red * (0.1879)).toFixed(2)
      // this.steam_red_percent=this.steam_red_percent+this.steam_red_percent
      console.log(this.steam_economy,this.steam_red_percent,this.steam_wl,"umaa")

    });
  }
 
}