<app-side-top-nav-bar [title]="heading" [dynamicPath]="'home'" [backPath]="'home'"> </app-side-top-nav-bar>
      <div class = "section">
      <div class = "row">
        <img src = "../../../../assets/feeder.png" class="feeder"/> 
         <img src = "../../../../assets/Conveyor.png" class="conveyor"/>
      </div>
      <div class = "row">
         <a (click) = "routeToModule('digester')"> <img src = "../../../../assets/digester.png"  height="19%" width="19%" class="digester"/> </a>
         <img src = "../../../../assets/forward-arrow.png" class = "forward-arrow-placement" /> &nbsp;
         <a (click) = "routeToModule('bleaching')"> <img src = "../../../../assets/bleaching.png"  height="19%" width="19%" class="bl-align"/> </a> &nbsp;
         <img src = "../../../../assets/forward-arrow.png" class = "fwd-arrow-2" /> &nbsp;
         <a (click) = "routeToModule('paper-machine')"> <img src = "../../../../assets/paper-machine.png" height="19%" width="19%" class="paper-machine"/> </a> &nbsp; 
      </div>
      <div>
         <img src = "../../../../assets/arrow-up.png" class="arrow-up"/> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
         <img src = "../../../../assets/arrow-down.png" />
      </div>
      <div (click) = "routeToModule('soda-recovery')">
         <img src = "../../../../assets/soda-recovery.png" height = "19%" width = "19%" class="placement-evaporator"/>
      </div>
      <div class = "flow-arrow-placement">
         <img src = "../../../../assets/flow-arrows.png" />
      </div>
   </div>
