import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EvaporatorComponent } from './components/evaporator/evaporator.component';
import { SliderComponent } from './components/slider/slider.component';
import { TopKpiComponent } from './components/top-kpi/top-kpi.component';
import { InputsComponent } from './components/inputs/inputs.component';
import { HomeScreenComponent } from './components/home-component/home-screen.component';
import { SideTopNavBarComponent } from './components/side-top-nav-bar/side-top-nav-bar.component';
import { SodaRecoveryComponent } from './components/soda-recovery/soda-recovery.component';
import { VapourComponent } from './components/vapour/vapour.component';
import { BlackLiquorComponent } from './components/black-liquor/black-liquor.component';
import { HeavyLiquorComponent } from './components/heavy-liquor/heavy-liquor.component';
import { GreenFlowComponent } from './components/green-flow/green-flow.component';
import { OrangeFlowComponent } from './components/orange-flow/orange-flow.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AuthenticationModule } from './authentication/authentication.module';
import { KpiOptComponent } from './components/kpi-opt/kpi-opt.component';

@NgModule({
  declarations: [
    AppComponent,
    EvaporatorComponent,
    SliderComponent,
    TopKpiComponent,
    InputsComponent,
    SideTopNavBarComponent,
    HomeScreenComponent,
    SodaRecoveryComponent,
    VapourComponent,
    BlackLiquorComponent,
    HeavyLiquorComponent,
    GreenFlowComponent,
    OrangeFlowComponent,
    KpiOptComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    AuthenticationModule.forRoot(
      'http://localhost:3100/auth/login',
      'Building Management',
      '0'
    ),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
